<template>
  <section>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="filterCategories">
          <div class="row">
            <div class="col-lg-6 col-md-6">
              <app-input v-model="filter.title" id="filter_title" :label="$t('category.filter.title')"></app-input>
            </div>
            <div class="col-lg-2 col-md-6">
              <app-input v-model="filter.id" id="filter_id" :label="$t('category.filter.id')"></app-input>
            </div>
            <div class="col-lg-4">
              <button class="btn btn-success pull-right m-t-30">
                {{ $t('category.filter.search') }}
              </button>
              <button
                type="button"
                class="btn btn-secondary m-t-30"
                data-test="reset_filter"
                @click="resetFilter"
              >
                {{ $t('buttons.reset_filter') }}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </section>
</template>
<script>
import Input from '../form/inputs/Input'
import CategoryFilter from '../../model/CategoryFilter'

export default {
  name: 'CategoryFilter',
  data () {
    return {
      isLoading: false,
      filter: this._.cloneDeep(CategoryFilter)
    }
  },
  components: {
    appInput: Input
  },
  methods: {
    filterCategories () {
      this.$store.commit('category/setPage', 1)
      this.$store.commit('category/setFilter', this.filter)
      this.$store.dispatch('category/fetch')
    },
    resetFilter () {
      this.filter = this._.cloneDeep(CategoryFilter)
      this.$store.commit('category/setFilter', this.filter)
      this.$store.dispatch('category/fetch')
    }
  },
  beforeMount () {
    this.filter = this.$store.getters['category/filter']
  }
}
</script>
